import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)


import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/style/custom.css'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

export const serverAddress = process.env.NODE_ENV === 'development' ? 'http://tstools.local/' : location.protocol + '//' + location.host + '/'

import ax from 'axios'
export const abortAllXHR = new AbortController()
export const axios = (data = {}, url = '', method = 'post', async = false) => {
    ax.defaults.withCredentials = true
    ax.defaults.maxContentLength = Infinity
    ax.defaults.maxBodyLength = Infinity

    let config = {
        url: serverAddress + url,
        method: method.toLowerCase(),
        [method.toLowerCase() === 'get' ? 'params' : 'data'] : data,
        signal: abortAllXHR.signal
    }

    if (async === true) {
        async function afd() {
            await ax.request(config)
        }
        return afd()
    } else {
        return ax.request(config)
    }
}

app.use(router)
app.use(Toast)
app.mount('#app')
